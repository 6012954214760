
import React from "react";
import MetaTags from 'react-meta-tags';

const MetaTag = ({pageTitle, description}) => {
  return (
    <>
        <MetaTags>
            <title>{pageTitle}</title>
            <meta name="description" content={description}/>
        </MetaTags>
    </>
  );
};

export default MetaTag;
